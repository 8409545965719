import React, { useEffect, useRef } from "react";
import Chat from "./Chat";
import ExpandOnHistory from "./ExpandOnHistory";
import MessageLoading from "./MessageLoading";
import { useGptContext } from "../../Context/GptContext";
import { HistoryItem } from "../../Models/Chat";

interface ChatHistoryProps {
    id: string,
    visibleExpandsOnHistory: boolean,
    onExpandOnHistoryItemClick: (history: HistoryItem) => void,
    onExpandOnHistoryToggle: (expanded: boolean) => void,
}

const ChatHistory: React.FC<ChatHistoryProps> = ({
    id,
    visibleExpandsOnHistory,
    onExpandOnHistoryItemClick,
    onExpandOnHistoryToggle
}) => {
    const chatContainerRef = useRef<HTMLInputElement>(null);
    const { messages, video, collection, loading } = useGptContext();

    const scrollToBottom = () => {
        setTimeout(() => {
            chatContainerRef?.current?.scroll({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth"
            });
        }, 100);
    };

    useEffect(() => {
        if (messages.length || loading) {
            scrollToBottom();
        }
    }, [messages.length, loading]);

    return (
        <>
            {video && collection && (
                <div id={id}>
                    <div
                        id="message-list"
                        className={`${visibleExpandsOnHistory ? "history-open" : ""}`}
                        ref={chatContainerRef}>
                        {messages.map((message, index) => (
                            <Chat
                                key={index}
                                message={message}
                                scrollToBottom={scrollToBottom}
                            />
                        ))}

                        <MessageLoading />

                        {/* Expand on history */}
                        <ExpandOnHistory
                            video={video}
                            collection={collection}
                            onItemClick={onExpandOnHistoryItemClick}
                            onExpandOnHistoryToggle={(expanded: boolean) => {
                                scrollToBottom();
                                onExpandOnHistoryToggle(expanded);
                            }}
                            visibleExpandsOnHistory={visibleExpandsOnHistory}
                        />
                    </div>
                </div>
            )}

        </>
    );
};

export default ChatHistory;
