import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import "./../styles/Title.scss";
import "./VideoPlayer.scss";
import QuizContainer from "./QuizContainer/QuizContainer";
import { useVideoPlayerContext } from "../Context/VideoContext";
import { Video } from "../Models/Video";
import { Question } from "../Models/Question";

interface YouTubePlayerProps {
    video: Video,
    questions: Question[]
}

const YouTubePlayer: React.FC<YouTubePlayerProps> = ({ video, questions }) => {
    const { currentTimeStamp } = useVideoPlayerContext();
    const [currentTime, setCurrentTime] = useState(currentTimeStamp);

    const [player, setPlayer] = useState<any>(null);

    const opts = {
        playerVars: {
            autoplay: 1
        }
    };

    const handleOnReady = (event: any) => {
        setPlayer(event.target);
        const player = event.target;
        if (!player) return;
        player.seekTo(currentTimeStamp);
        player.playVideo();
    };

    const onEnd = () => {
        if (!player) return;
        player.seekTo(0);
    };

    const pauseVideo = (time: number) => {
        if (!player) return;
        player.seekTo(time);
        player.pause();
    };

    useEffect(() => {
        if (!player) return;
        const interval = setInterval(async () => {
            const elapsed_sec = await player.getCurrentTime();
            setCurrentTime(elapsed_sec);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [player]);

    return (
        <>
            <div className="video-player-container">
                <YouTube
                    videoId={video.embedId}
                    iframeClassName="video-player-responsive-iframe"
                    opts={opts}
                    onReady={handleOnReady}
                    onEnd={onEnd}
                />
            </div>

            <QuizContainer
                questions={questions}
                currentTime={currentTime}
                pauseVideo={pauseVideo}
            />
        </>
    );
};

export default YouTubePlayer;
