export const secondsToHms = (
    seconds: string | number,
    includeSeconds: boolean = true
) => {
    seconds = Number(seconds);
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);
    const hDisplay = h > 0 ? h + "hr " : "";
    const mDisplay = m > 0 ? m + "min " : "";
    const sDisplay = includeSeconds && s > 0 ? s + "sec" : "";
    return hDisplay + mDisplay + sDisplay;
};
