import { UpCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

interface TopUpFormProps { }

const TopUpForm: React.FC<TopUpFormProps> = (props) => {
    return (
        <>
            <Button size="small" style={{ display: "flex", marginLeft: "auto", alignItems: "center" }} icon={<UpCircleOutlined />}>
                Recharge
            </Button>
        </>
    )
}

export default TopUpForm;