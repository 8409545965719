import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaBars, FaBug, FaHome, FaListAlt, FaQuestionCircle, FaSignOutAlt, FaUser, FaWallet } from "react-icons/fa";
import Logo from "../Logo/Logo";
import { useAppContext } from "../../Context/AppContext";
import { useEffect, useState } from "react";
import { Button, Drawer, Input, Layout, Menu } from "antd";
import { ArrowLeftOutlined, CloseOutlined, HomeOutlined, InfoCircleOutlined, MailOutlined, MenuOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import "./MobileHeader.scss";
import { LOGOUT_URL } from "../../const";

interface MobileHeaderProps {
    searchBar?: boolean,
    searchBarText?: string,
    showBackButton?: boolean,
    title?: string,
    onSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const { Header } = Layout;
const headerPaths: any = {
    '/': "1",
    '/collections/': "2",
    '/my-collections/': "3",
    '/faq/': "4",
    '/bugreport/': "5",
}

const MobileHeader = (props: MobileHeaderProps) => {

    const { searchBar, onSearch, searchBarText, showBackButton = true, title } =
        props;

    const { authenticated, navbarOpen, setNavbarOpen, searchBarOpen, setSearchBarOpen, user } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedKey, setSelectedKey] = useState<string>("1");

    const toggleSearch = () => {
        setSearchBarOpen(!searchBarOpen);
    };

    const toggleMenu = () => {
        setNavbarOpen(!navbarOpen);
    };

    const closeSideBar = () => {
        setNavbarOpen(false);
    };

    const logout = () => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        localStorage.removeItem("userId");
        closeSideBar();
    };


    useEffect(() => {
        if (location.pathname in headerPaths) {
            setSelectedKey(headerPaths[location.pathname])
        } else {
            setSelectedKey("1");
        }
    }, [location.pathname]);


    const SideBarContent = () => {
        return (
            <>
                <Menu
                    mode="inline"
                    selectedKeys={[selectedKey]}
                    className="side-bar-menu"
                >
                    <Menu.Item key="1" icon={<HomeOutlined />}>
                        <Link to="/" onClick={closeSideBar}>Home</Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<FaListAlt />}>
                        <Link to="/collections/" onClick={closeSideBar}>Premium Courses</Link>
                    </Menu.Item>
                    {authenticated && (
                        <>
                            <Menu.Item key="3" icon={<FaListAlt />}>
                                <Link to="/my-collections/" onClick={closeSideBar}>My Courses</Link>
                            </Menu.Item>
                            <Menu.Item key="8" icon={<FaWallet />}>
                                <Link to="/wallet/" onClick={closeSideBar}>
                                    Account / Wallet (<small>{user?.primaryWallet?.cupointsBalance} cupoints</small>)
                                </Link>
                            </Menu.Item>
                        </>
                    )}
                    <Menu.Item key="4" icon={<FaQuestionCircle />}>
                        <Link to="/faq/" onClick={closeSideBar}>FAQ</Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<FaBug />}>
                        <Link to="/bugreport/" onClick={closeSideBar}>REPORT AN ISSUE</Link>
                    </Menu.Item>
                    {authenticated ? (
                        <Menu.Item key="6" icon={<FaSignOutAlt />}>
                            <Link to={LOGOUT_URL} onClick={logout}>Logout</Link>
                        </Menu.Item>
                    ) : (
                        <Menu.Item key="7" icon={<FaUser />}>
                            <Link to="/login/" onClick={closeSideBar}>Login</Link>
                        </Menu.Item>
                    )}
                </Menu>
            </>
        );
    };

    const goBack = () => {
        navigate(-1);
    };


    return (
        <>
            <Header className="mobile-header fixed-header">
                <div className="left-section">
                    {showBackButton && (
                        <Button
                            type="text"
                            className="back-btn"
                            icon={<ArrowLeftOutlined />}
                            onClick={goBack}
                        />
                    )}

                    {title ? (
                        <div className="mobile-header-title">{title}</div>
                    ) : (
                        <div className="logo">
                            <Link to="/">
                                <Logo className={"header-logo"} />
                            </Link>
                        </div>
                    )}
                </div>

                <div className="right-section">

                    {searchBar && (
                        <Button
                            type="text"
                            icon={searchBarOpen ? <CloseOutlined /> : <SearchOutlined />}
                            onClick={toggleSearch}
                        />
                    )}

                    <Button
                        className="menu-icon"
                        type="text"
                        icon={<MenuOutlined />}
                        onClick={toggleMenu}
                    />
                </div>
            </Header>

            {searchBar && searchBarOpen && (
                <div className="fixed-search-bar">
                    <Input
                        className="search-input"
                        placeholder={`${searchBarText}...`}
                        allowClear
                        onChange={onSearch}
                    />
                </div>
            )}

            <Drawer
                title="Menu"
                placement="right"
                onClose={toggleMenu}
                open={navbarOpen}
                bodyStyle={{ padding: 0 }}
            >
                <SideBarContent />
            </Drawer>
        </>
    );
};
export default MobileHeader;
