import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";

export interface VideoPlayerContextProps {
    currentTimeStamp: number;
    setCurrentTimeStamp?: () => void;
    globalTimeStamp?: number;
    updateGlobalTimeStamp?: (timestamp: number) => void;
}

const defaultValue: VideoPlayerContextProps = {
    currentTimeStamp: 0,
    setCurrentTimeStamp: () => { },
    globalTimeStamp: 0,
    updateGlobalTimeStamp: (timestamp: number) => { }
}

const VideoPlayerContext = createContext<VideoPlayerContextProps>(defaultValue);
export const useVideoPlayerContext = () => useContext(VideoPlayerContext);

interface VideoPlayerProviderProps {
    children: ReactNode;
}

export const VideoPlayerProvider: React.FC<VideoPlayerProviderProps> = ({ children }) => {

    const [currentTimeStamp, setCurrentTimeStamp] = useState<number>(0);

    const updateGlobalTimeStamp = useCallback((ts: number) => {
        setCurrentTimeStamp(ts);
    }, [currentTimeStamp]);

    const contextValue = useMemo(
        () => ({
            currentTimeStamp,
            updateGlobalTimeStamp
        }),
        [currentTimeStamp]
    );

    return (
        <VideoPlayerContext.Provider value={contextValue}>
            {children}
        </VideoPlayerContext.Provider>
    );
};
