import React, {
    createContext,
    useContext,
    useMemo,
    useState,
    ReactNode
} from "react";
import { Course } from "../Models/Course";
import { User } from "../Models/User";

export interface AppContextProps {
    loadingApp: boolean;
    setLoadingApp: React.Dispatch<React.SetStateAction<boolean>>;
    user: User | null;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    showLoader: boolean;
    setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
    navbarOpen: boolean;
    setNavbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    searchBarOpen: boolean;
    setSearchBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    authenticated: boolean;
    setAuthenticate: React.Dispatch<React.SetStateAction<boolean>>;
    collectionsLoaded: boolean;
    setCollectionsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    collections: Course[];
    setCollections: React.Dispatch<React.SetStateAction<Course[]>>;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);;
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

interface AppProviderProps {
    children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [showLoader, setShowLoader] = useState(true);
    const [user, setUser] = useState<User | null>(null);
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [searchBarOpen, setSearchBarOpen] = useState(false);
    const [authenticated, setAuthenticate] = useState(false);
    const [loadingApp, setLoadingApp] = useState(true);
    const [collectionsLoaded, setCollectionsLoaded] = useState(false);
    const [collections, setCollections] = useState<Course[]>([]);

    const contextValue = useMemo<AppContextProps>(
        () => ({
            user,
            loadingApp,
            showLoader,
            navbarOpen,
            authenticated,
            collectionsLoaded,
            collections,
            searchBarOpen,
            setSearchBarOpen,
            setShowLoader,
            setNavbarOpen,
            setAuthenticate,
            setLoadingApp,
            setCollectionsLoaded,
            setCollections,
            setUser
        }),
        [
            loadingApp,
            user,
            showLoader,
            navbarOpen,
            searchBarOpen,
            authenticated,
            collectionsLoaded,
            collections
        ]
    );

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};
