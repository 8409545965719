import React, { useState } from "react";
import "./Quiz.scss";
import { Question } from "../Models/Question";

interface QuizProps {
    matchingQuestion: Question,
    onBack: () => void
}

const Quiz: React.FC<QuizProps> = ({ matchingQuestion, onBack }) => {
    const [selectedOption, setSelectedOption] = useState<number | null>(null);
    const [showResult, setShowResult] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [tip, setTip] = useState("");
    const handleOptionSelect = (index: number) => {
        setSelectedOption(index);
    };

    const handleCheckAnswer = () => {
        if (selectedOption !== null) {
            if (matchingQuestion) {
                const isCorrect =
                    matchingQuestion.options?.[selectedOption]
                        .correctAnswerFlag;
                const tip =
                    matchingQuestion.options?.[selectedOption].explanationText
                        .blocks?.[0].text;
                setIsCorrect(isCorrect); // Update the isCorrect state variable
                setTip(tip); // Set the tip for the selected answer
                setShowResult(true); // Show the result of the selected answer
            }
        }
    };

    return (
        <div>
            {/* Display the quiz question and answer options if a matching question is provided */}
            {matchingQuestion && (
                <div className="quiz-field">
                    <h3 className="question-title">{matchingQuestion.title}</h3>

                    {matchingQuestion.images?.map((imageFile, index) => (
                        <div key={index}>
                            <img
                                alt="example"
                                style={{ width: "100%" }}
                                src={imageFile.image}
                            />
                        </div>
                    ))}

                    <p className="question-text">
                        {matchingQuestion?.text?.blocks?.[0]?.text}
                    </p>
                    {/* Map through the answer options and display them */}
                    {matchingQuestion &&
                        matchingQuestion.options &&
                        matchingQuestion.options.length > 0
                        ? matchingQuestion.options.map((options, index) => (
                            <div
                                key={index}
                                className={`question-option ${selectedOption === index ? "selected" : ""
                                    }`}
                                onClick={() => handleOptionSelect(index)} // Call handleOptionSelect when an option is clicked
                            >
                                <input
                                    type="radio"
                                    id={`option-${index}`}
                                    name="quiz-option"
                                    checked={selectedOption === index}
                                    onChange={() => handleOptionSelect(index)} // Call handleOptionSelect when an option is selected
                                />

                                <label htmlFor={`option-${index}`}>
                                    {
                                        matchingQuestion.options?.[index]
                                            ?.optionText?.blocks?.[0].text
                                    }
                                </label>
                                {/* Check if the option has an image and render it if available */}
                                <div className="image-grid">
                                    {matchingQuestion.options?.[index]
                                        ?.image && (
                                            <img
                                                className="option-image"
                                                src={
                                                    matchingQuestion.options?.[
                                                        index
                                                    ]?.image?.image
                                                }
                                                alt={`Option ${index + 1}`}
                                            />
                                        )}
                                </div>
                            </div>
                        ))
                        : null}

                    {/* Show the result and tip if showResult is true */}
                    {showResult && (
                        <div
                            className={`answer-tip ${isCorrect ? "success" : "error"
                                }`}>
                            {tip}
                        </div>
                    )}

                    <div
                        className="btn-container"
                        style={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                            marginTop: "0.5rem",
                            marginBottom: "1rem"
                        }}>
                        <button onClick={onBack} className="light-btn">
                            Close
                        </button>
                        <button
                            className="default-btn"
                            onClick={handleCheckAnswer}>
                            Submit
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Quiz;
