import React from "react";
import { Link } from "react-router-dom";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";
import { Col, Row, Typography } from "antd";
import { API_URL } from "../../const";
import ContactUsForm from "../../Components/ContactUsForm";
import { useAppContext } from "../../Context/AppContext";
const { Text, Title } = Typography;

const FaqPage = () => {
    const { searchBarOpen } = useAppContext();

    return (
        <>
            <MobileHeader title={"Frequently Asked Questions"} />
            <div className={`page ${searchBarOpen ? "search-bar-open" : ""}`}>
                <div className="faq-containr">
                    <Row gutter={[40, 40]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>What is CUbits?</Title>
                                <Text style={{ fontSize: 15 }}>
                                    An AI-driven curated* video learning
                                    platform that allows instructors and authors
                                    to create dynamic video supplements from
                                    their own video playlists or from public
                                    videos. CUbits premium videos (
                                    <Link href="https://www.cubits.ai/collections/">
                                        https://www.cubits.ai/collections/
                                    </Link>
                                    ) can be adopted by any instructor to
                                    enhance their courses. CUbits was founded by
                                    Princeton University faculty and students to
                                    enhance teaching and learning and now
                                    available for anyone to use.
                                </Text>
                                <br /> <br />
                                <Text>
                                    *Curated Video is a term used by CUbits to
                                    describe a platform where video, speech,
                                    text, interactivity, and community, all
                                    combined to give an immersive, interactive,
                                    and collaborative experience for students
                                    and instructors.
                                </Text>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    What are the major benefits to instructors?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    Student motivation, engagement and
                                    accountability are some of the most
                                    challenging problems in education. CUbits
                                    was designed to address all of that and
                                    more. Motivate students to learn beyond
                                    lecture using curated videos. Engage
                                    students with interactivity and real-time
                                    dashboards. Make students accountable
                                    through various notification and scheduling
                                    tools. Tailor instructions and intervene
                                    with struggling students early. Once you
                                    build a collection once, you can continue to
                                    improve and reuse from semester to semester.
                                    See{" "}
                                    <Text italic>more instructor benefits</Text>{" "}
                                    at -{" "}
                                    <Link href="https://bit.ly/3q346du">
                                        https://bit.ly/3q346du
                                    </Link>
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    What are the major benefits to students?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    Videos are a major learning resource for
                                    students in any course these days. If
                                    instructor does not provide video resources,
                                    students likely waste time on YouTube
                                    looking for videos, often finding incomplete
                                    or inaccurate videos, getting distracted by
                                    commercials and unrelated videos. CUbits
                                    help eliminate or reduce all that
                                    substantially. CUbits videos are curated
                                    from ordinary videos, vetted by instructor,
                                    structured, searchable, interactive and have
                                    collaboration built in. Students find
                                    curated videos to be the second most
                                    important resource behind in-person lectures
                                    in a traditional course. Studies show that
                                    providing interactive videos increase exam
                                    and quiz grades among most students.
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    I am interested. How do I get started?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    Just visit{" "}
                                    <Link href="https://www.cubits.ai/">
                                        https://www.cubits.ai/
                                    </Link>
                                    , to create a free instructor/aggregator
                                    account and curate your own collection from
                                    any YouTube or Vimeo playlist in minutes. Or
                                    adopt premium videos that are already
                                    available and integrate with your own
                                    videos. Either way you will see the benefits
                                    of curation immediately. See{" "}
                                    <Text italic>
                                        how-to-build-my-video-course
                                    </Text>{" "}
                                    -{" "}
                                    <Link href="https://bit.ly/3q346du">
                                        https://bit.ly/3q346du
                                    </Link>
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    I am an instructor/author. Can I evaluate
                                    platform and premium videos for free?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    Sure. Just send email to: demo@cubits.ai to
                                    receive instructions on how to get access to
                                    complementary premium videos. If you want to
                                    build your own collection, create an account{" "}
                                    <Link
                                        href={`${API_URL}/accounts/signup/`}>{`${API_URL}/accounts/signup/`}</Link>{" "}
                                    and get started for free. See{" "}
                                    <Text italic>
                                        how-to-build-my-video-course
                                    </Text>{" "}
                                    -{" "}
                                    <Link href="https://bit.ly/3q346du">
                                        https://bit.ly/3q346du
                                    </Link>
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    I do not have time to record my own videos,
                                    are there premium videos already available
                                    on CUbits?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    Yes. We have prominent author and publisher
                                    built CS video collections. You can find and
                                    adopt them at{" "}
                                    <Link href="https://www.cubits.ai/collections/">
                                        https://www.cubits.ai/collections/
                                    </Link>
                                    . The cost is as low as $2 per module
                                    (students can purchase as many modules as
                                    needed using a micro-payment system). We
                                    have exclusive partnerships with Princeton
                                    University Press and other publishers to
                                    bring more curated videos to the market
                                    soon.
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    I am currently teaching a flipped class or
                                    interested in teaching one. What are the
                                    benefits of using CUbits platform for a
                                    flipped class?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    Typical flipped courses just assign videos
                                    to students but lack tools and techniques to
                                    keep students accountable and engaged with
                                    videos. We developed this technology to
                                    address some of those challenges that we had
                                    at our courses at Princeton. If you are
                                    teaching a flipped or using an alternate
                                    teaching method, benefits of this platform
                                    are enormous.
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    I am NOT teaching a flipped class. Should I
                                    still provide videos to my students?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    Absolutely. Videos are a major content
                                    source for this generation of students.
                                    Interactive videos are visual and engaging.
                                    On average, 84% of students frequently go to
                                    YouTube looking for videos (based on a
                                    survey done by Temple University in 2021
                                    with 3000+ respondents). However, most
                                    students are not capable of sorting through
                                    millions of search hits. They often find
                                    content that are incomplete or inaccurate.
                                    By curating and providing an{" "}
                                    <Text underline>instructor certified</Text>{" "}
                                    video collection, you can reduce student
                                    anxiety as they look for additional
                                    resources and increase course outcomes.
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    I have my own pre-recorded videos. How do I
                                    curate them for my students?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    The process is simple. You host videos as a
                                    YouTube or Vimeo playlist, integrate them to
                                    CUbits through simple UI (no video uploads
                                    necessary), build your own curated video
                                    course in minutes. See{" "}
                                    <Text italic>
                                        how-to-build-my-video-course
                                    </Text>{" "}
                                    user manual -{" "}
                                    <Link href="https://bit.ly/3q346du">
                                        https://bit.ly/3q346du
                                    </Link>
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    Is the platform free, if I use my own
                                    videos, public videos, or premium videos?{" "}
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    Yes. You can build a curated collection and
                                    use the basic tools for free with your own
                                    students. We hope you will become a premier
                                    author and market your high-quality curated
                                    modules to learners outside of your
                                    institution at affordable prices
                                    ($2-$5/module). Please contact{" "}
                                    <Link href="mailto:support@cuvids.io">
                                        support@cuvids.io
                                    </Link>{" "}
                                    for more information.
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    I currently host videos for free on YouTube
                                    or using a course management system. What
                                    additional benefits do students and
                                    instructors get for the paid subscription?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    After a free semester, we charge a very
                                    nominal fee of $10/student to provide
                                    structure, interactivity, auto-grading,
                                    video collaboration and all advanced
                                    dashboard tools to help improve student
                                    learning and manage teaching at scale.
                                    CUbits is AI-driven, built to increase
                                    student engagement and course outcomes with
                                    guaranteed success. Students get to use AI
                                    dashboards, recommendations and get a
                                    personalized learning experience.
                                    Instructors get real-time insights into how
                                    students engage with course material. It is
                                    unlike any other platform you know, built to
                                    solve some of the most challenging problems
                                    in education, student engagement,
                                    accountability, and outcomes..
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    I DO NOT have my own pre-recorded videos.
                                    But can I adopt some free videos from
                                    YouTube or Vimeo to curate?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    Sure. Under YouTube license, you can embed
                                    and use any free YouTube videos on a
                                    third-party website. You just need to be
                                    aware that the owner of the free videos may
                                    later move or remove videos from YouTube and
                                    your collection may need updates. Don’t
                                    worry, you can easily update your collection
                                    anytime using editing tools available on
                                    CUbits by adding or removing videos.
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    What steps are involved if I curate my own
                                    video playlist?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    No need to upload anything. You bring your
                                    existing YouTube/Vimeo playlist to create a
                                    structure for your course, add interactive
                                    exercises, enable Q&A, dashboard, reporting
                                    and many other advanced features. We will
                                    add video indexing and searchability for
                                    free. It takes a few hours to curate a video
                                    course, but course can be updated anytime,
                                    maintained, and reused from semester to
                                    semester. See{" "}
                                    <Text italic>
                                        how-to-maintain-my-course
                                    </Text>{" "}
                                    at -{" "}
                                    <Link href="https://bit.ly/3q346du">
                                        https://bit.ly/3q346du
                                    </Link>
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    What are some ways to increase student
                                    accountability, participation, and outcomes?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    CUbits come with many advanced tools to keep
                                    students engaged. Offering some bonus points
                                    for participation goes a long way to
                                    motivate students to keep up with learning
                                    activities and reduce the possibility of
                                    course failure. Students/instructors use the
                                    CUbits dashboards to see how they are doing
                                    at any time. Join a free summer workshop to
                                    learn how.
                                </Text>
                                <br />
                                <Link href="https://forms.gle/Pz6n7g6Ri4jjjmzq8">
                                    https://forms.gle/Pz6n7g6Ri4jjjmzq8
                                </Link>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    I am interested in distributing and
                                    monetizing my video content. How do I start?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    CUbits is a marketplace for video authors to
                                    distribute their content and get paid as
                                    users purchase them. We offer high royalty
                                    rates depending on the amount of videos
                                    sold. Please contact pricing@cubits.ai for
                                    more information.
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    Is there a mobile version of CUbits?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    <Link href="https://www.cubits.ai/">
                                        https://www.cubits.ai/
                                    </Link>{" "}
                                    is optimized for mobile access. We plan to
                                    release mobile only apps soon. Stay tuned
                                    for updates.
                                </Text>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="faq-card">
                                <Title level={4}>
                                    I have more questions. Who do I contact?
                                </Title>
                                <Text style={{ fontSize: 15 }}>
                                    Please fill out the form below to let us
                                    know what you need. We will get back to you
                                    promptly.
                                </Text>
                            </div>
                        </Col>
                    </Row>

                    <ContactUsForm />
                </div>
            </div>
        </>
    );
};

export default FaqPage;
