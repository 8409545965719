import React, { useState } from "react";
import "./ButtonWithImages.scss"; // Import the CSS file for styling
import { Link } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { Video } from "../../Models/Video";
import { Access } from "../../Models/ModuleAccess";

interface ButtonWithImagesProps {
    name: string,
    videos: Video[],
    collectionId: number,
    moduleIndex: number,
    locked: boolean,
    moduleAccess: { [key: string]: Access }
}

interface VideoPreviewProps {
    video: Video,
}

// Define a functional component called ButtonWithImages that takes in three props: name, videos, and collection
// and displays the corresponding the collection modules. When a  module is clicked on displays that modules videos
function ButtonWithImages({ name, videos, collectionId, moduleIndex, locked }: ButtonWithImagesProps) {
    const [showImgContainer, setShowImgContainer] = useState(false);

    // toggle viewing an image container
    function toggleImgContainer() {
        setShowImgContainer(!showImgContainer);
    }

    const LockPreview = () => {
        return (
            <div className="lock-wrapper">
                <FaLock />
            </div>
        );
    };

    const VideoPreview = ({ video }: VideoPreviewProps) => {
        return (
            <>
                <div className="video">
                    {locked && <LockPreview />}
                    {video.videoType === "youtube" ? (
                        <img
                            key={video.id}
                            className="btn-img"
                            src={`http://img.youtube.com/vi/${video.embedId}/0.jpg`}
                            alt={video.title + " Youtube Thumbnail"}
                        />
                    ) : (
                        <img
                            key={video.id}
                            className="btn-img"
                            src={`https://vumbnail.com/${video.embedId}.jpg`}
                            alt={video.title + " Vimeo Thumbnail"}
                        />
                    )}
                    <h1 className="video-title">{video.title}</h1>
                </div>
            </>
        );
    };

    // Render the component
    return (
        <div className="btn-container">
            <button className="moduleName" onClick={toggleImgContainer}>
                {name}
            </button>
            <div className={`img-container ${showImgContainer ? "open" : ""}`}>
                {showImgContainer &&
                    videos.map((video, videoIndex) => (
                        <div key={videoIndex}>
                            {locked ? (
                                <VideoPreview video={video} />
                            ) : (
                                <Link
                                    to={`/collections/${collectionId}/modules/${moduleIndex + 1
                                        }/videos/${videoIndex + 1}`}>
                                    <VideoPreview video={video} />
                                </Link>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default ButtonWithImages;
