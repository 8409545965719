import { memo } from "react";
import { FaLock } from "react-icons/fa";

const AccessDenied = () => {
    return (
        <div className="access-denied">
            <h3>
                <FaLock /> Access Denied
            </h3>
        </div>
    );
};

export default memo(AccessDenied);
