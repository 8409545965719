import React, { useEffect, useRef, useState } from "react";
import "./CollectionsPage.scss";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";
import CollectionsList from "../../Components/CollectionsList/CollectionsList";
import "../../styles/Title.scss";
import { Course } from "../../Models/Course";
import { useAppContext } from "../../Context/AppContext";

const CollectionsPage = () => {
    const { setShowLoader, collections, collectionsLoaded, searchBarOpen } =
        useAppContext();
    const dataFetchedRef = useRef(true);
    const [filteredCollections, setFilteredCollections] = useState<Course[]>([]);

    useEffect(() => {
        if (collectionsLoaded) {
            setFilteredCollections(collections);
            setShowLoader(false);
        } else {
            setShowLoader(true);
        }
    }, [collectionsLoaded]);

    useEffect(() => {
        // if (dataFetchedRef.current) {
        //     dataFetchedRef.current = false;
        // }
    }, []);

    // function that handles setting the current value when the search button is clicked.
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        if (!query) {
            setFilteredCollections([...collections]);
            return;
        }

        const tempList = [...collections];
        const searchedCollections = tempList.filter((collection: any) =>
            collection.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCollections(searchedCollections);
    };

    return (
        <>
            <MobileHeader
                searchBar={true}
                onSearch={handleSearch}
                searchBarText={"Search courses"}
            />

            <div className={`page ${searchBarOpen ? 'search-bar-open' : ''}`}>
                {collections === null ? (
                    <p>No courses</p>
                ) : (
                    <CollectionsList collections={filteredCollections} />
                )}
            </div>
        </>
    );
};

export default CollectionsPage;
