import "./LoginPage.scss";
import { LOGIN_URL, SIGNUP_URL } from "../../const";
import Logo from "../../Components/Logo/Logo";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";

const LoginPage: React.FC = () => {
    const goto = (url: string) => {
        window.location.href = url;
    };
    return (
        <>
            <MobileHeader
                searchBar={false}></MobileHeader>
            <div className={"login-wrapper"}>
                <div className="logo-wrapper">
                    <Logo className="login-picture"></Logo>
                </div>
                <p className="text-center">
                    AI-driven Learning Portal of Curated Video Courses created
                    by University Authors
                </p>
                <p className="text-center">
                    <button
                        className="signin-btn default-btn btn-block"
                        onClick={() => {
                            goto(LOGIN_URL);
                        }}>
                        Sign In
                    </button>
                    <button
                        className="signup-btn light-btn btn-block"
                        onClick={() => {
                            goto(SIGNUP_URL);
                        }}>
                        Sign Up
                    </button>
                </p>
            </div>
        </>
    );
};

export default LoginPage;
