// DEV
// export const API_URL = "http://127.0.0.1:8000";
// export const LOGIN_URL =
//     "http://127.0.0.1:8000/accounts/login/?next=https://localhost:3000";
// export const SIGNUP_URL =
//     "http://127.0.0.1:8000/accounts/signup/?next=https://localhost:3000";
// export const LOGOUT_URL =
//     "http://127.0.0.1:8000/accounts/logout/?next=https://localhost:3000";
// export const IS_PROD = false;
// export const WEBSITE_URL = "https://cubits.ai";

// PROD
export const API_URL = "https://www.api.cubits.ai";
export const LOGIN_URL =
    "https://www.api.cubits.ai/accounts/login/?next=https://m.cubits.ai";
export const SIGNUP_URL =
    "https://www.api.cubits.ai/accounts/signup/?next=https://m.cubits.ai";
export const LOGOUT_URL =
    "https://www.api.cubits.ai/accounts/logout/?next=https://m.cubits.ai";
export const IS_PROD = true;
export const WEBSITE_URL = "https://cubits.ai";
