import React from "react";
import ContactUsForm from "../../Components/ContactUsForm";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";
import { useAppContext } from "../../Context/AppContext";

const BugReportPage: React.FC = () => {
    const { searchBarOpen } = useAppContext();
    return (
        <>
            <MobileHeader title={"Contact Us"} />
            <div className={`page ${searchBarOpen ? 'search-bar-open' : ''}`}>
                <ContactUsForm />
            </div>
        </>
    );
}

export default BugReportPage;
