import React, { useEffect, useState } from "react";
import Quiz from "../Quiz";
import { Timeline } from "antd";
import { FaArrowRight } from "react-icons/fa";
import { Question } from "../../Models/Question";

interface QuizContainerProps {
    questions: Question[],
    currentTime: number,
    pauseVideo: (timestamp: number) => void
}

const QuizContainer: React.FC<QuizContainerProps> = ({ questions, currentTime, pauseVideo }: QuizContainerProps) => {
    const [matchingQuestions, setMatchingQuestions] = useState<Question[]>([]);

    const formatTimestamp = (timestamp: number) => {
        return Math.floor(timestamp);
    };

    const convertSecondsToVideoTime = (seconds: number) => {
        var minutes = Math.floor(seconds / 60);
        var minuteString = `${minutes}`.padStart(2, "0");
        var secondsString = `${seconds - minutes * 60}`.padStart(2, "0");
        return `${minuteString}:${secondsString}`;
    };

    const filterQuestions = (currentTime: number) => {
        if (!questions.length) return;

        const formattedTimestamp = formatTimestamp(currentTime);
        const filteredMatchingQuestions = questions.filter(
            (q) => q.endVideoTimestamp === formattedTimestamp
        );
        setMatchingQuestions(filteredMatchingQuestions);

        // Pause the video if a matching question is found
        if (filteredMatchingQuestions.length > 0) {
            pauseVideo(currentTime);
        }
    };

    const switchToQuestion = (question: Question) => {
        setMatchingQuestions([question]);
        pauseVideo(question.endVideoTimestamp);
    };

    useEffect(() => {
        filterQuestions(currentTime);
    }, [currentTime]);

    return (
        <>
            {matchingQuestions.length ? (
                <>
                    {matchingQuestions.map((matchingQuestion, i) => (
                        <Quiz
                            key={i}
                            matchingQuestion={matchingQuestion}
                            onBack={() => {
                                pauseVideo(
                                    matchingQuestion.endVideoTimestamp + 1
                                );
                                setMatchingQuestions([]);
                            }}
                        />
                    ))}
                </>
            ) : (
                <div className="quiz-container-list">
                    {/* Map through the questions and display clickable timestamps */}
                    <h3 className="upcoming-quiz-title">
                        Upcoming Quiz Questions
                    </h3>

                    <Timeline mode="left">
                        {questions.map((question, i) => (
                            <Timeline.Item key={i} color="grey">
                                <div className="quiz-timeline-item">
                                    <div className="first-row">
                                        <button
                                            onClick={() => {
                                                pauseVideo(
                                                    question.startVideoTimestamp
                                                );
                                            }}>
                                            {convertSecondsToVideoTime(
                                                question.startVideoTimestamp
                                            )}
                                        </button>
                                        <FaArrowRight />
                                        <button
                                            onClick={() => {
                                                pauseVideo(
                                                    question.endVideoTimestamp
                                                );
                                            }}>
                                            {convertSecondsToVideoTime(
                                                question.endVideoTimestamp
                                            )}
                                        </button>
                                    </div>
                                    <div className="sec-row">
                                        <span>{question.title}</span>
                                        <button
                                            className="light-btn"
                                            onClick={() => {
                                                switchToQuestion(question);
                                            }}>
                                            Open Question
                                        </button>
                                    </div>
                                </div>
                            </Timeline.Item>
                        ))}
                    </Timeline>
                </div>
            )}
        </>
    );
};

export default QuizContainer;
