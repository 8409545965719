import React from 'react';
import './MobileModal.scss';
import { Sheet } from 'react-modal-sheet';
interface MobileModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const MobileModal: React.FC<MobileModalProps> = ({ isOpen, onClose, children }) => {
    return (
        <>
            <Sheet
                isOpen={isOpen}
                onClose={onClose}
                tweenConfig={
                    { ease: 'easeOut', duration: 1 }
                }
                detent="full-height">
                <Sheet.Container>
                    <Sheet.Header></Sheet.Header>
                    <Sheet.Content>
                        <div className='modal-wrapper'>
                            <Sheet.Scroller>
                                {children}
                            </Sheet.Scroller>
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet >
        </>
    );
};

export default MobileModal;