import React from "react";
import "./App.scss";

import { AppProvider } from "./Context/AppContext";
import Main from "./Main";

const App: React.FC = () => {

    return (
        <AppProvider>
            <Main />
        </AppProvider>
    );
}

export default App;
