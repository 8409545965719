import React from "react";
import "./HomePage.scss";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";
import LearningSVG from "./learning.svg";
import AnalyticsSVG from "./analytics.svg";
import WatchSVG from "./video.svg";
import DoneSVG from "./done.svg";
import PlatformSVG from "./platform.svg";
import InteractiveSVG from "./interactive.svg";
import { PlayCircleOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import { Button } from "antd";
import { useAppContext } from "../../Context/AppContext";

function HomePage() {

    const { searchBarOpen } = useAppContext();

    return (
        <>
            <MobileHeader showBackButton={false} searchBar={false} ></MobileHeader>
            <div className={`page ${searchBarOpen ? "search-bar-open" : ""}`}>
                <div className="home-page-container">
                    <div className="home-page-landing">
                        {/* Home Page Heading Section */}
                        <div id="home-page-heading">
                            <img src={LearningSVG} className="home-page-landing-image" />
                            <h1>
                                Empowering Minds through <br />
                                <span className="multi-sensory">
                                    Multi-Sensory
                                </span>{" "}
                                Learning
                            </h1>
                            <p>
                                A Repository of AI-boosted & Certified Skills
                                Courses in One Place
                            </p>
                            <div id="home-page-landing-btns">
                                <Link
                                    to="/collections/"
                                    id="home-page-explore-btn">
                                    Explore Courses
                                </Link>{" "}
                                {/* <Link
                                    to="/certifications/"
                                    id="be-an-author-btn">
                                    Get a Certificate
                                </Link>
                                <Link to="/authors/" id="be-an-author-btn">
                                    Build a Course
                                </Link> */}
                            </div>
                        </div>

                        {/* Home Page Why Cubits Section */}
                        <div id="why-cubits-works">
                            <h2>Why Cubits?</h2>
                            <div>
                                <div>
                                    <img src={WatchSVG} className="watch-img" />
                                    <div id="why-cubits-works-watch">
                                        <p className={"home-img-caption-title"}>
                                            Watch
                                        </p>
                                        <p className={"home-img-caption"}>
                                            Watch videos within structured
                                            courses alongside synchronized
                                            transcripts
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img src={DoneSVG} className="done-img" />
                                    <div id="why-cubits-works-interact">
                                        <p className={"home-img-caption-title"}>
                                            Interact
                                        </p>
                                        <p className={"home-img-caption"}>
                                            Increase engagement through Embedded
                                            Quizzes and Video Discussions
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <img src={AnalyticsSVG} className="analyze-img" />
                                    <div id="why-cubits-works-analyze">
                                        <p className={"home-img-caption-title"}>
                                            Analyze
                                        </p>
                                        <p className={"home-img-caption"}>
                                            Visualize your
                                            engagement/performance and compare
                                            with the class statistics
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Home Page Instructor Section */}
                        <div id="home-instructors-section-container">
                            <div id="home-instructors-section">
                                <div id="home-instructors-section-text">
                                    <h2>FOR INSTRUCTORS</h2>

                                    <p>
                                        Personalize video courses using new or
                                        existing videos that are instantly ready
                                        to be distributed to students
                                    </p>
                                </div>
                                <div>
                                    <img src={PlatformSVG} />
                                </div>
                            </div>
                        </div>

                        {/* Home Page For Learners Section */}
                        <div id="home-learners-section-container">
                            <div id="home-learners-section">
                                <h2>FOR LEARNERS</h2>
                                <div id="home-learners-section-visual">
                                    <img src={InteractiveSVG} />
                                </div>
                                <div id="home-learners-section-text">
                                    <p>
                                        Learn and engage through a
                                        comprehensive, interactive platform.
                                        Learn from trusted instructors, always
                                        know where to look
                                    </p>
                                    <Link to="/collections/">
                                        <Button
                                            id="home-learners-watch-btn"
                                            className="explore-btn">
                                            <PlayCircleOutlined /> Watch
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomePage;
