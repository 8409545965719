import React from "react";
import "./WalletPage.scss";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";
import { useAppContext } from "../../Context/AppContext";
import { Button, Card, Col, Descriptions, Row, Space, Statistic, Table, Tag, Tooltip, Typography } from "antd";
import TopUpForm from "../../Components/TopUpForm/TopUpForm";
import { ArrowUpOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { API_URL } from "../../const";
import { FaCog } from "react-icons/fa";

const { Title, Text } = Typography;
const columns: any[] = [
    { title: 'Order #', dataIndex: 'orderId', key: 'orderId', responsive: ['sm'] },
    {
        title: 'Transaction Type', dataIndex: 'transactionType', key: 'transationType', render: (type: string) => {
            switch (type) {
                case "topup":
                    return <Tag color="#87d068">{type.toUpperCase()}</Tag>
                case "purchase":
                    return <Tag color="#f50">{type.toUpperCase()}</Tag>
                case "refund":
                    return <Tag color="#FCAE1E">{type.toUpperCase()}</Tag>
                default:
                    return
            }
        }
    },
    { title: 'Cupoints Amount', dataIndex: 'transactionTotalCupoints', key: 'transactionTotalCupoints', },
    { title: 'Currency Amount', dataIndex: 'transactionTotalCurrency', key: 'transactionTotalCurrency', responsive: ['md'] },
    { title: 'Discount', dataIndex: 'discount', key: 'discount', render: (discount: string) => { return `${parseFloat(discount) * 100}%` }, responsive: ['lg'] },
    { title: "Memo", dataIndex: 'memo', key: 'memo', responsive: ['lg'] },
    {
        title: "Date/Time", dataIndex: 'date', key: 'date', render: (transactionDate: string) => {
            var date = new Date(transactionDate)
            return <Text>{date.toDateString()}</Text>
        }
    },
    {
        title: 'Action', dataIndex: '', key: 'refundable', render: (instance: any) => {
            return "";
            // <TransactionRefundButton transaction={instance} issueRefund={issueRefund} user={user} />
        }, responsive: ['md']
    }
]

interface WalletPageProps { }

const WalletPage: React.FC<WalletPageProps> = (props) => {
    const { searchBarOpen, user } =
        useAppContext();

    return (
        <>
            <MobileHeader
                searchBar={false}
            />

            <div className={`page ${searchBarOpen ? 'search-bar-open' : ''}`}>
                <div className="wallet-page-wrapper">
                    {user ? (<>

                        <div className="centered-container">
                            <br />
                            <Card
                                className="site-page-header-responsive"
                                title={'Account Dashboard'}
                                headStyle={{ padding: "12px" }}
                                bodyStyle={{ padding: "12px" }}
                                extra={[<Space className="ant-space-responsive">
                                    <Button type="link" style={{ color: "gray" }} icon={<FaCog />} href={API_URL + "/accounts/settings/"} target="_blank">
                                    </Button>
                                </Space>]}
                            >
                                <Descriptions size="small" column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
                                    <Descriptions.Item label="Username">{user.username}</Descriptions.Item>
                                    <Descriptions.Item label="Join Date">{new Date(user.dateJoined).toDateString()}</Descriptions.Item>
                                    <Descriptions.Item label="Account Type">{user.isContentCreator ? "Content Creator" : "General User"}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                            <br />
                            <Card
                                className="site-page-header-responsive"
                                headStyle={{ padding: "12px" }}
                                bodyStyle={{ padding: "12px" }}
                                title="My Wallet"
                                extra={<Tooltip title="CUvids wallet is a new and an economical way to subscribe to studio produced curated videos as necessary. You decide when and what videos to subscribe. Based on your needs, you may spend $10 or more to get what you need. A minimum deposit to a wallet of $10 buys 1000 cupoints that can be used to subscribe to up to 25 curated videos. Each cuvids module (4-6 videos) is priced at 200 cupoints or $2." color="cyan">
                                    <Text type="secondary">What is a wallet?</Text>
                                </Tooltip>}
                            >
                                <Row gutter={[16, 32]} style={{ width: "100%", rowGap: "12px" }}>
                                    <Col xs={24} sm={12} md={12} lg={6} xl={6} >
                                        <Statistic title="Wallet Currency Abbreviation" valueStyle={{ fontSize: "12px" }} value={user.primaryWallet.currency.abbreviation} />
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={6} xl={8} >
                                        <Statistic title="Wallet Currency Name" valueStyle={{ fontSize: "12px" }} value={user.primaryWallet.currency.fullName} />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                                        <Statistic valueStyle={{ color: '#3f8600', display: "flex", marginLeft: "auto", alignItems: "center" }} title={<><Text color="#00000073">Available Cupoints </Text><Tooltip title="Cupoints is an internal point system used to reward authors and gamify the platform. 100 cupoints = 1 US $"><QuestionCircleOutlined /></Tooltip></>} value={user.primaryWallet.cupointsBalance} />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={4} xl={4} >
                                        <TopUpForm />
                                    </Col>
                                    <Col span={24}>
                                        <Title level={5} type="secondary">Transactions</Title>
                                        <Table className="transaction-table" columns={columns} dataSource={user.primaryWallet.transactions} rowKey="id" bordered={true} />
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </>) : (
                        <>Not Authorized</>
                    )}
                </div>
            </div>
        </>
    )
}

export default WalletPage;