import React from "react";
import "./CollectionsList.scss";
import { Link } from "react-router-dom";
import {
    FolderOutlined,
    PlaySquareOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import { secondsToHms } from "../../utils";
import { Course } from "../../Models/Course";

interface CollectionsListProps {
    collections: Course[]
}

function CollectionsList(props: CollectionsListProps) {
    const { collections } = props;

    return (
        <div className="collection-list-container">
            {collections.map((collection, i) => {
                return (
                    <Link key={i} to={"/collections/" + collection.id}>
                        <div className="collection collection-list-item">
                            <div className="collection-item-picture">
                                <img
                                    className="collection-module-img"
                                    src={collection.coverImage.image}
                                    alt="Collection Thumbnail"
                                />
                            </div>
                            <div className="collection-item-header">
                                <h3>{collection.name}</h3>
                                {collection?.primaryAuthor?.lastName && (
                                    <div className="author-name">
                                        By {collection?.primaryAuthor.firstName}{" "}
                                        {collection?.primaryAuthor.lastName}
                                    </div>
                                )}
                                <div className="collection-details-bullets">
                                    {collection?.moduleCount > 0 && (
                                        <span>
                                            <FolderOutlined />
                                            {collection.moduleCount}
                                        </span>
                                    )}
                                    {collection?.videoCount > 0 && (
                                        <span>
                                            <VideoCameraOutlined />
                                            {collection.videoCount}
                                        </span>
                                    )}
                                    {collection?.totalDuration > 0 && (
                                        <span>
                                            <PlaySquareOutlined />
                                            {secondsToHms(
                                                collection.totalDuration || 0,
                                                false
                                            )}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            })}
        </div>
    );
}

export default CollectionsList;
