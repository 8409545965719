import React from "react";
import "./FloatingButtons.scss";

type FloatingButtonsProps = {
    children?: React.ReactNode
};
const FloatingButtons: React.FC<FloatingButtonsProps> = ({ children }) => {
    return (
        <div className="floating-button-container">
            <div className="floating-button-list">
                {children}
            </div>
        </div>
    )
}

export default FloatingButtons;