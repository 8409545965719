import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { API_URL } from "../../const";
import { Course } from "../../Models/Course";
import { Video } from "../../Models/Video";
import { HistoryItem } from "../../Models/Chat";

interface ExpandOnHistoryProps {
    video: Video,
    collection: Course,
    visibleExpandsOnHistory: boolean,
    onItemClick: (history: HistoryItem) => void,
    onExpandOnHistoryToggle: (visible: boolean) => void,
}

const ExpandOnHistory: React.FC<ExpandOnHistoryProps> = ({
    video,
    collection,
    visibleExpandsOnHistory,
    onItemClick,
    onExpandOnHistoryToggle
}) => {
    const [historyList, setHistoryList] = useState<HistoryItem[]>([]);

    const fetchExpandOnHistory = () => {
        fetch(API_URL + `/api/v1/gpt/last-expand-on/${collection.id}/${video.id}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Network response was not ok");
                }
            })
            .then((response) => {
                if (response.data) {
                    setHistoryList(response.data);
                }
            });
    };

    const toggleExpandItems = () => {
        onExpandOnHistoryToggle(!visibleExpandsOnHistory);
    };

    useEffect(() => {
        fetchExpandOnHistory();
    }, [video]);

    return (
        <div
            id="expand-on-history-list"
            className={`expand-on-history-list-items ${visibleExpandsOnHistory ? "open" : ""
                } ${historyList.length > 0 ? "visible" : ""}`}>
            {historyList.length > 0 && (
                <>
                    <div className="header" onClick={() => toggleExpandItems()}>
                        <h2>What others asked before?</h2>
                        <Button
                            size="small"
                            shape="circle"
                            type="link"
                            icon={
                                visibleExpandsOnHistory ? (
                                    <FaChevronDown />
                                ) : (
                                    <FaChevronUp />
                                )
                            }></Button>
                    </div>
                    {visibleExpandsOnHistory && (
                        <>
                            {historyList.map((history, index) => (
                                <div
                                    className="expand-on-history-list-item user-box"
                                    key={index}
                                    onClick={() => onItemClick(history)}>
                                    {history.question}
                                </div>
                            ))}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default ExpandOnHistory;
