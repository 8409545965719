import "./Transcript.scss";
import { Transcript as TranscriptModel } from "../../Models/Transcript";
import { useVideoPlayerContext } from "../../Context/VideoContext";

interface TranscriptProps {
    transcripts: TranscriptModel[],
    handleToggle: () => void,
    transcriptSearchQuery: string
}

function Transcript({ transcripts, handleToggle, transcriptSearchQuery }: TranscriptProps) {
    const { updateGlobalTimeStamp } = useVideoPlayerContext();

    const convertToSeconds = (time: string) => {
        const timeParts = time.split(":");

        // Check the length of the array to determine the format of the input
        if (timeParts.length === 3) {
            // Format: hr:min:sec
            const hours = parseInt(timeParts[0]);
            const minutes = parseInt(timeParts[1]);
            const seconds = parseInt(timeParts[2]);
            return hours * 3600 + minutes * 60 + seconds;
        } else if (timeParts.length === 2) {
            // Format: min:sec
            const minutes = parseInt(timeParts[0]);
            const seconds = parseInt(timeParts[1]);
            return minutes * 60 + seconds;
        } else {
            // Format: sec
            const seconds = parseInt(timeParts[0]);
            return seconds;
        }
    };

    const onTranscriptClick = (line: TranscriptModel) => {
        // TODO: uncomment here
        // updateGlobalTimeStamp(line.startTime);
        handleToggle();
    };

    return (
        <div className="parent-container">
            <div className="transcript-text">
                {/* Each sentence is wrapped in a separate span element */}
                {transcripts.map((line: TranscriptModel, i: number) => {
                    const searchText = transcriptSearchQuery.toLowerCase();
                    const lineText = line.text.toLowerCase();
                    const seconds = convertToSeconds(searchText);
                    const isMatch = searchText
                        ? lineText.includes(searchText) ||
                        (seconds >= line.startTime && seconds <= line.endTime)
                        : false;
                    const className = `sentence${line.startOfParagraph ? " start-paragraph" : ""
                        } ${isMatch ? "match" : ""}`;
                    return (
                        <span
                            key={i}
                            className={className}
                            onClick={() => {
                                onTranscriptClick(line);
                            }}>
                            {line.text} { }
                        </span>
                    );
                })}
            </div>
        </div>
    );
}

export default Transcript;
