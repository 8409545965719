import { Navigate } from "react-router-dom";
import { useAppContext } from "./Context/AppContext";

interface ProtectedRouteProps {
    element: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const { authenticated } = useAppContext();
    return authenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
