import React, { useRef, useState, useEffect, useMemo } from "react";
import Vimeo, { TimeEvent } from "@vimeo/player";
import "./../styles/Title.scss";
import "./VideoPlayer.scss";
import QuizContainer from "./QuizContainer/QuizContainer";
import { useVideoPlayerContext } from "../Context/VideoContext";
import { Question } from "../Models/Question";
import { Video } from "../Models/Video";
import Player from "@vimeo/player";

interface VimeoPlayerProps {
    video: Video, questions: Question[]
}

const VimeoPlayer: React.FC<VimeoPlayerProps> = ({ video, questions }) => {
    const vimeoRef = useRef<any>(null);
    const { currentTimeStamp } = useVideoPlayerContext();
    const [currentTime, setCurrentTime] = useState(currentTimeStamp);

    const initializeVimeoPlayer = () => {
        const player: Player = new Vimeo("vimeo-player-iframe", {
            id: Number(video.embedId)
        });

        player.on("loaded", () => {
            player.setCurrentTime(currentTimeStamp);
        });

        player.on("timeupdate", (currentTimeStamp: TimeEvent) => {
            setCurrentTime(currentTimeStamp.seconds);
        });

        vimeoRef.current = player;
    };

    const pauseVideo = (time: number) => {
        vimeoRef.current.setCurrentTime(time);
        vimeoRef.current.pause();
    };

    useEffect(() => {
        if (vimeoRef.current === null) {
            initializeVimeoPlayer();
        }

        return () => {
            vimeoRef.current.unload();
        };
    }, []);

    return useMemo(() => {
        return (
            <>
                <div className="video-player-container">
                    <iframe
                        id="vimeo-player-iframe"
                        className="video-player-responsive-iframe"
                        src={`https://player.vimeo.com/video/${video.embedId}`}
                        width="320"
                        height="195"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen></iframe>
                </div>

                <QuizContainer
                    questions={questions}
                    currentTime={currentTime}
                    pauseVideo={pauseVideo}
                />
            </>
        );
    }, []);
};

export default VimeoPlayer;
