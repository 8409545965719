import Typewriter from "typewriter-effect";
import { FaCircleNotch } from "react-icons/fa";
import { useGptContext } from "../../Context/GptContext";

const MessageLoading: React.FC = () => {
    const { loading } = useGptContext();

    return (
        <>
            <div id="loading">
                {loading ? (
                    <>
                        {/* icon="fa-circle-notch" */}
                        <FaCircleNotch
                            className="spinner"
                        />
                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .typeString(
                                        "loading... (it may take up to 20 seconds)"
                                    )
                                    .pauseFor(20000)
                                    .deleteAll(1)
                                    .typeString(
                                        "still loading... (taking longer than usual)"
                                    )
                                    .pauseFor(10000)
                                    .deleteAll(1)
                                    .typeString(
                                        "taking too long ... (you may want to return later)"
                                    )
                                    .start();
                            }}
                            options={{
                                delay: 0
                                // cursor: "."
                            }}
                        />
                    </>
                ) : null}
            </div>
        </>
    );
};

export default MessageLoading;
