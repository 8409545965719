import React, { useState } from 'react';
import './style.scss'
import Typewriter from "typewriter-effect";

interface TextProps {
    message: string,

}

const Text: React.FC<TextProps> = ({ message }) => {

    return (<div id="response-message">

        {/* <div dangerouslySetInnerHTML={{__html:message.replace("\n", "<br /><br />") }}></div> */}

        {message}

        {/*                 
        {!stopGenerating ? <Typewriter
            onInit={(typewriter) => {
            typewriter
                .typeString(message)
                .start();}}
                
                options={{
                    delay: 30,
                    }} /> : null} */}
    </div>)
}

export default Text