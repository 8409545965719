import React, {
    useEffect,
    useRef
} from "react";
import "./ModuleContent.scss";
import { useParams } from "react-router-dom";
import { useState } from "react";
import MobileHeader from "./MobileHeader/MobileHeader";
import { API_URL } from "../const";
import moment from "moment";
import AccessDenied from "./AccessDenied/AccessDenied";
import { Tabs } from "antd";
import { FaVideo, FaListAlt, FaRocketchat } from "react-icons/fa";
import VimeoPlayer from "./VimeoPlayer";
import YouTubePlayer from "./YouTubePlayer";
import ChatbotContainer from "./CubitsGPT/ChatbotContainer";
import Transcript from "./Transcript/Transcript";
import { Course, Module } from "../Models/Course";
import { Access, ModuleAccesses } from "../Models/ModuleAccess";
import { Video } from "../Models/Video";
import { useAppContext } from "../Context/AppContext";
import { useVideoPlayerContext, VideoPlayerProvider } from "../Context/VideoContext";

type VideoPageParamsType = {
    collectionId: string,
    moduleNumber: string,
    videoNumber: string
}

const VideoPage = () => {
    const { setShowLoader, searchBarOpen } = useAppContext();
    const dataFetchedRef = useRef(true);
    const videoPlayerContext = useVideoPlayerContext();

    const tabs = [
        { key: "1", title: "Video", icon: FaVideo, gpt: false },
        { key: "2", title: "Transcript", icon: FaListAlt, gpt: false },
        { key: "3", title: "cuGPT", icon: FaRocketchat, gpt: true }
    ];

    let { collectionId, moduleNumber, videoNumber } = useParams<VideoPageParamsType>();


    const [accessDenied, setAccessDenided] = useState(false);
    const [collection, setCollection] = useState<Course | null>(null);
    const [module, setModule] = useState<Module | null>(null);
    const [video, setVideo] = useState<Video | null>(null);
    const [questions, setQuestions] = useState([]);
    const [transcripts, setTranscripts] = useState([]);
    const [transcriptSearchQuery, setTranscriptSearchQuery] = useState<string>("");
    const [isCollectionOwner, setIsCollectionOwner] = useState(false);

    const [activeTab, setActiveTab] = useState("1");

    const isNotLocked = (moduleAccess: { [key: string]: Access }, moduleId: number) => {
        const moduleAccessObj = moduleAccess[moduleId];
        if (!moduleAccessObj) return false;

        if (moduleAccessObj.accessType == "permanent") return true;

        if (moduleAccessObj.accessType == "temporary") {
            let currentDate = moment(new Date());
            let openDate = moment(new Date(moduleAccessObj.accessFrom)).startOf(
                "day"
            );
            let closeDate = moment(new Date(moduleAccessObj.accessUntil)).endOf(
                "day"
            );
            return currentDate >= openDate && currentDate < closeDate;
        }

        return false;
    };

    const bindData = (collectionData: Course, moduleAccess: { [key: string]: Access }) => {
        const module = collectionData.modules[Number(moduleNumber) - 1];
        setModule(module);

        const video = module.videos[Number(videoNumber) - 1];
        setVideo(video);

        // check is locked
        const notLocked = isNotLocked(moduleAccess, module.id);
        setAccessDenided(!notLocked);
        if (!notLocked) return;

        // Fetch Questions
        fetchQuestions(Number(collectionId), module.id, video.id);

        // Fetch transcripts
        fetchTranscript(video.id);
    };

    const fetchCollection = (collectionId: number) => {
        setShowLoader(true);
        fetch(`${API_URL}/api/v1/collections/${collectionId}/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((data: Course) => {
                if (!data) return;

                setCollection(data);
                const loggedUserIdStr: string | null = localStorage.getItem("userId");
                const loggedUserId = loggedUserIdStr !== null && Boolean(loggedUserIdStr) ? parseInt(loggedUserIdStr) : 0

                setIsCollectionOwner(
                    Boolean(loggedUserId) &&
                    data?.primaryAuthor?.id === loggedUserId
                );
                fetchModuleAccess(collectionId, data);
            });
    };

    const fetchModuleAccess = (collectionId: number, collectionData: Course) => {
        fetch(`${API_URL}/api/v1/collections/${collectionId}/module-access/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.moduleAccesses) {
                    bindData(collectionData, data.moduleAccesses);
                }
                setShowLoader(false);
            });
    };

    const fetchQuestions = (collectionId: number, moduleId: number, videoId: number) => {
        fetch(
            API_URL +
            `/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/quizquestions/`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response: Response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Failed to load quiz questions.");
                }
            })
            .then((data) => {
                setQuestions(data);
            })
            .catch((error) => { });
    };

    const fetchTranscript = (videoId: number) => {
        fetch(API_URL + `/api/v1/videos/${videoId}/transcripts/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Failed to load transcripts.");
                }
            })
            .then((data) => {
                setTranscripts(data);
            })
            .catch((error) => { });
    };

    const handleModuleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setTranscriptSearchQuery(query);
    };

    const handleTranscriptToggle = () => {
        setActiveTab("1");
    };

    const VideoTab = () => {
        return (
            <>
                {video && (
                    <>
                        <div className="player-container">
                            {video.videoType === "youtube" && (
                                <YouTubePlayer
                                    video={video}
                                    questions={questions}
                                />
                            )}
                            {video.videoType === "vimeo" && (
                                <VimeoPlayer
                                    video={video}
                                    questions={questions}
                                />
                            )}
                        </div>
                    </>
                )}
            </>
        );
    };
    const TranscriptTab = () => {
        return (
            <Transcript
                transcripts={transcripts}
                handleToggle={handleTranscriptToggle}
                transcriptSearchQuery={transcriptSearchQuery}
            />
        );
    };

    const CuGptTab = () => {
        return (
            <>
                {video && collection && (
                    <ChatbotContainer
                        video={video}
                        collection={collection}
                        isCollectionOwner={isCollectionOwner}
                        pauseVideo={() => { }}
                    />
                )}
            </>
        );
    };

    const DynamicTabContent = () => {
        return (
            <>
                <VideoPlayerProvider>
                    {/* Video */}
                    {activeTab === "1" && <VideoTab />}
                    {/* Transcript */}
                    {activeTab === "2" && <TranscriptTab />}
                    {/* cuGPT */}
                    {activeTab === "3" && <CuGptTab />}
                </VideoPlayerProvider>
            </>
        );
    };

    useEffect(() => {
        if (dataFetchedRef.current) {
            dataFetchedRef.current = false;
            fetchCollection(Number(collectionId));
        }
    }, []);

    return (
        <>
            <MobileHeader
                searchBar={activeTab === "2"}
                onSearch={handleModuleSearch}
                searchBarText={"Search Transcripts"}
                title={video?.title}
            />

            <div className={`page ${searchBarOpen ? 'search-bar-open' : ''}`}>
                {accessDenied ? (
                    <AccessDenied />
                ) : (
                    <>
                        {video && (
                            <Tabs
                                activeKey={activeTab}
                                defaultActiveKey={"1"}
                                centered
                                size={"large"}
                                onChange={(key) => {
                                    setActiveTab(key);
                                }}
                                items={tabs
                                    .filter((t) => {
                                        return (
                                            !t.gpt ||
                                            (t.gpt && collection?.gptEnabled)
                                        );
                                    })
                                    .map((tab, i) => {
                                        return {
                                            label: (
                                                <span className="tab-content-x">
                                                    <tab.icon /> {tab.title}
                                                </span>
                                            ),
                                            key: tab.key,
                                            children: <DynamicTabContent />
                                        };
                                    })}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default VideoPage;
