import React, { useEffect, useRef } from "react";
import "./ModuleContent.scss";
import { useParams } from "react-router-dom";
import ButtonWithImages from "./ButtonWithImages/ButtonWithImages";
import { useState } from "react";
import MobileHeader from "./MobileHeader/MobileHeader";
import { API_URL } from "../const";
import moment from "moment";
import { useAppContext } from "../Context/AppContext";
import { Course, Module } from "../Models/Course";
import { Access } from "../Models/ModuleAccess";
import { Button, Image, Typography } from "antd";
import { FaPlayCircle } from "react-icons/fa";
import { FolderOutlined, PlaySquareOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { secondsToHms } from "../utils";
import FloatingButtons from "./FloatingButtons/FloatingButtons";
import IntroPreviewer from "./IntroPreviewer/IntroPreviewer";
import MobileModal from "./MobileModal/MobileModal";

const { Paragraph, Text, Title } = Typography;

function ModuleContent() {
    const { authenticated, setShowLoader, searchBarOpen } = useAppContext();

    const dataFetchedRef = useRef(true);
    let { collectionId } = useParams();

    const [collection, setCollection] = useState<Course | null>(null);
    const [moduleAccess, setModuleAccess] = useState<{ [key: string]: Access }>({});
    const [filteredModules, setFilteredModules] = useState<Module[]>([]);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState<boolean>(false);

    const fetchCollection = (collectionId: number) => {
        setShowLoader(true);
        fetch(`${API_URL}/api/v1/collections/${collectionId}/`, {
            method: "GET",
            headers: (authenticated ? {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            } : { "Content-Type": "application/json" })
        })
            .then((response) => response.json())
            .then((data: Course) => {
                setCollection(data);
                if (data) {
                    setFilteredModules([...data.modules]);
                }
                fetchModuleAccess(collectionId);
            });
    };

    const fetchModuleAccess = (collectionId: number) => {
        fetch(`${API_URL}/api/v1/collections/${collectionId}/module-access/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.moduleAccesses) {
                    setModuleAccess(data.moduleAccesses);
                }
                setShowLoader(false);
            });
    };

    const handleModuleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!collection) return;

        const query = event.target.value;
        if (!query) {
            setFilteredModules([...collection.modules]);
            return;
        }

        // Filter modules based on query
        const filterd =
            Array.isArray(collection.modules) && collection.modules.length !== 0
                ? [...collection.modules].filter(
                    (module) =>
                        module.name
                            .toLowerCase()
                            .includes(query.toLowerCase()) ||
                        module.videos.some((video) =>
                            video.title
                                .toLowerCase()
                                .includes(query.toLowerCase())
                        )
                )
                : [];

        setFilteredModules(filterd);
    };

    const isNotLocked = (moduleId: number) => {
        const moduleAccessObj = moduleAccess[moduleId];
        if (!moduleAccessObj) return false;

        if (moduleAccessObj.accessType == "permanent") return true;

        if (moduleAccessObj.accessType == "temporary") {
            let currentDate = moment(new Date());
            let openDate = moment(new Date(moduleAccessObj.accessFrom)).startOf(
                "day"
            );
            let closeDate = moment(new Date(moduleAccessObj.accessUntil)).endOf(
                "day"
            );
            return currentDate >= openDate && currentDate < closeDate;
        }

        return false;
    };

    useEffect(() => {
        if (dataFetchedRef.current) {
            dataFetchedRef.current = false;
            fetchCollection(Number(collectionId));
        }
    }, []);

    return (
        <>
            <MobileHeader
                searchBar={true}
                onSearch={handleModuleSearch}
                searchBarText={"Search Modules"}
            />

            {collection !== null ? (
                <div className={`page ${searchBarOpen ? 'search-bar-open' : ''}`}>
                    <div className="collection-information-wrapper">
                        <div className="image-wrapper">
                            <IntroPreviewer
                                collection={collection}
                                videoType={
                                    collection?.introVideoType
                                }
                                videoUrl={
                                    collection?.introVideoUrl
                                }
                                thumbnail={
                                    <Image
                                        src={collection.coverImage.image}
                                        preview={false}
                                        width={"100%"}
                                        className="myCollection-img"
                                    />
                                }
                            />
                            {/* <img
                                className="myCollection-img "
                                src={collection.coverImage.image}
                                alt="Collection Image"
                            />
                            {Boolean(collection.introVideoUrl) && (<Button className="play-intro-btn" color="dark" size="small" icon={<FaPlayCircle />}>Play Intro</Button>)} */}
                        </div>
                        <div className="details-wrapper">
                            <h3 className="collection-name">
                                {collection.name}
                            </h3>
                            <h6 className="collection-author">
                                By {collection.createdByUsername}
                            </h6>
                            <div className="collection-details-bullets">
                                {collection?.moduleCount > 0 && (
                                    <span>
                                        <FolderOutlined />
                                        {collection.moduleCount} Modules
                                    </span>
                                )}
                                {collection?.videoCount > 0 && (
                                    <span>
                                        <VideoCameraOutlined />
                                        {collection.videoCount} Videos
                                    </span>
                                )}
                                {collection?.totalDuration > 0 && (
                                    <span>
                                        <PlaySquareOutlined />
                                        {secondsToHms(
                                            collection.totalDuration || 0,
                                            false
                                        )} total duration
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="button-set">
                        <FloatingButtons>
                            <Button size="small" color="secondary" className={"active"}>Modules</Button>
                            <Button size="small" color="secondary" onClick={() => setIsDetailModalVisible(true)} className="">More</Button>
                            <Button size="small" className="gold-button">Certification</Button>
                        </FloatingButtons>
                    </div>

                    {/* Collection Details */}
                    {isDetailModalVisible && (
                        <MobileModal isOpen={isDetailModalVisible} onClose={() => setIsDetailModalVisible(false)} >
                            <div className="collection-details-modal-wrapper">
                                <Title level={5} color="secondary" className="collection-name">
                                    {collection.name}
                                </Title>
                                <h6 className="collection-author">
                                    By {collection.createdByUsername}
                                </h6>
                                <div className="collection-details-bullets">
                                    {collection?.moduleCount > 0 && (
                                        <span>
                                            <FolderOutlined />
                                            {collection.moduleCount} Modules
                                        </span>
                                    )}
                                    {collection?.videoCount > 0 && (
                                        <span>
                                            <VideoCameraOutlined />
                                            {collection.videoCount} Videos
                                        </span>
                                    )}
                                    {collection?.totalDuration > 0 && (
                                        <span>
                                            <PlaySquareOutlined />
                                            {secondsToHms(
                                                collection.totalDuration || 0,
                                                false
                                            )} total duration
                                        </span>
                                    )}
                                </div>
                                <Paragraph className="collection-info-card-description">
                                    <Text type="secondary" style={{ display: "flex" }}>
                                        {collection.description}
                                    </Text>
                                </Paragraph>
                                <Paragraph className="collection-info-card-long-description">
                                    {collection.longDescription}
                                </Paragraph>
                            </div>
                        </MobileModal>
                    )}


                    <div className="moduleList">
                        {Array.isArray(collection?.modules) &&
                            collection.modules.length !== 0 && (
                                <div className="module-list">
                                    <ul className="button-group">
                                        {filteredModules.map(
                                            (module, moduleIndex) => (
                                                <div
                                                    className="collection-module"
                                                    key={moduleIndex}>
                                                    <li className="button-item">
                                                        <ButtonWithImages
                                                            name={module.name}
                                                            videos={module.videos}
                                                            collectionId={
                                                                collection.id
                                                            }
                                                            moduleIndex={
                                                                moduleIndex
                                                            }
                                                            moduleAccess={
                                                                moduleAccess
                                                            }
                                                            locked={
                                                                !isNotLocked(
                                                                    module.id
                                                                )
                                                            }
                                                        />
                                                    </li>
                                                </div>
                                            )
                                        )}
                                    </ul>
                                </div>
                            )}
                    </div>
                </div>
            ) : (<></>)}
        </>
    );
}

export default ModuleContent;
