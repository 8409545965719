import { Video } from "./Video";

export enum GptElements {
    WELCOME = 0,
    SUMMARY = 1,
    QUESTION = 2,
    EXPANDS_ON = 3
}

export interface Message {
    user: string;
    content: string;
    mcq: boolean;
    element: GptElements;
    elementId: number | undefined;
    video: Video;
    isCollectionOwner: boolean;
}

export interface HistoryItem {
    id: number;
    question: string;
    answer: string;
    createdAt: string;
    updatedAt: string;
    video: number;
    createdBy: number;
}

export interface GptMCQQuestion {
    question: string;
    options: { [key: string]: GptMCQQuestionOption };
    explanation: string;
}

export interface GptMCQQuestionOption {
    Text: string;
    is_correct: boolean;
}

export interface MessageQuery {
    text: string;
    message: string;
    history?: HistoryItem
}
