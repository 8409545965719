import { GptElements, Message } from "../Models/Chat";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Video } from "../Models/Video";
import { Course, Module } from "../Models/Course";

export interface GptContextProps {
    video: Video,
    collection: Course,
    module: Module,
    isCollectionOwner: boolean,
    messages: Message[];
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    pauseVideo: React.Dispatch<React.SetStateAction<boolean>>;
}

const GptContext = createContext<GptContextProps | undefined>(undefined);
export const useGptContext = () => {
    const context = useContext(GptContext);
    if (!context) {
        throw new Error('useGptContext must be used within an GptProvider');
    }
    return context;
};

interface GptContextProviderProps {
    video: Video,
    collection: Course,
    children: ReactNode;
    isCollectionOwner: boolean;
    pauseVideo: () => void
}

export const GptProvider: React.FC<GptContextProviderProps> = ({ video, collection, isCollectionOwner, children, pauseVideo }) => {

    let welcome: Message = {
        user: "WELCOME",
        content: "Hi! How can I help you today?",
        mcq: false,
        element: GptElements.WELCOME,
        elementId: 0,
        video: video,
        isCollectionOwner: isCollectionOwner
    };

    const [messages, setMessages] = useState([welcome]);
    const [loading, setLoading] = useState(false);
    const [module, setModule] = useState<Module>({} as Module);

    useEffect(() => {
        const m = collection.modules.find((mod) => {
            return mod.videos.find((v) => v.id === video.id) !== undefined;
        });
        if (m) {
            setModule(m);
        }
    }, [collection]);

    const contextValue = useMemo(
        () => ({
            messages,
            setMessages,
            loading,
            setLoading,
            video,
            collection,
            module,
            isCollectionOwner,
            pauseVideo
        }),
        [messages, loading, video, collection, module, isCollectionOwner]
    );

    return (
        <GptContext.Provider value={contextValue}>
            {children}
        </GptContext.Provider>
    );
};
