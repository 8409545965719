import React, { useState, useRef, useEffect } from "react";
import {
    FaThumbsUp,
    FaThumbsDown,
    FaInfoCircle,
    FaPaperPlane,
    FaCheckCircle
} from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { API_URL } from "../../const";
import { useGptContext } from "../../Context/GptContext";


interface FeedbackProps {
    element: any,
    elementId: number
}

interface FeedbackModalProps {
    isOpen: boolean,
    onClose: () => void,
    onSend: (message: string) => void
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ isOpen, onClose, onSend }) => {
    const [feedback, setFeedback] = useState("");
    const modalRef = useRef<HTMLInputElement>(null);

    const handleFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFeedback(event.target.value);
    };

    const handleSend = () => {
        onSend(feedback);
        setFeedback("");
    };

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        const handleClickOutside = (event: TouchEvent | MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                handleClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClose]);

    return isOpen ? (
        <div
            ref={modalRef}
            className="modal"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
            <AiOutlineClose
                style={{ alignSelf: "flex-end", cursor: "pointer" }}
                onClick={handleClose}
            />
            <h2
                style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "2em"
                }}>
                Provide additional feedback
            </h2>
            <textarea
                className="chat-comp"
                value={feedback}
                onChange={handleFeedbackChange}
                style={{
                    backgroundColor: "rgba(207, 230, 220, 0.85)",
                    width: "90%",
                    height: "120px",
                    fontSize: "1em",
                    margin: "0 auto",
                    display: "flex"
                }}
            />
            <FaPaperPlane
                style={{
                    alignSelf: "flex-end",
                    cursor: "pointer",
                    color: feedback === "" ? "lightgrey" : "white",
                    width: "25px",
                    height: "25px",
                    marginTop: "10px"
                }}
                onClick={handleSend}
                aria-disabled={feedback === ""}
            />
        </div>
    ) : null;
};

const Feedback: React.FC<FeedbackProps> = ({ element, elementId }) => {
    const { video, collection } = useGptContext();
    if (!video || !collection) {
        return null;
    }

    const [feedback, setFeedback] = useState<number | null>(null);
    const [feedbackId, setFeedbackId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
    const [feedbackPercentage, setFeedbackPercentage] = useState<number>(-1);

    const sendFeedback = (rating: number, message = "") => {
        const data = {
            rating: rating,
            feedback: message,
            element: element,
            elementId: elementId,
            videoId: video.id,
            collectionId: collection.id
        };

        fetch(API_URL + `/api/v1/gpt/set-feedback`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((result) => {
                if (!result) return;

                // If down vote, open additional feedback modal
                if (rating === 0) {
                    setIsModalOpen(true);
                }

                setFeedbackId(result.id);
                if (result.rate > 0) {
                    setFeedbackPercentage(result.rate);
                }
            })

            .catch((error) => {
                console.error("Error submitting data:", error);
            })
            .finally(() => {
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                }, 10000);
            });
    };

    const onFeedbackTextSubmit = (message: string) => {
        setIsModalOpen(false);
        const data = {
            feedback: message,
            id: feedbackId
        };
        fetch(API_URL + `/api/v1/gpt/set-feedback-comment`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((result) => {
                if (!result) return;
            })

            .catch((error) => {
                console.error("Error submitting data:", error);
            })
            .finally(() => {
                setShowSuccess(false);
                setFeedbackPercentage(-1);
                setShowUpdateSuccess(true);
                setTimeout(() => {
                    setShowUpdateSuccess(false);
                }, 10000);
            });
    };

    const likeUnlikeClick = (vote: number) => {
        setFeedback(vote);
        sendFeedback(vote);
    };

    return (
        <div id="feedback-provider">
            <div className="rating">
                {feedback === null && (
                    <>
                        {element === 2 ? (
                            <p className="feedback-text">
                                <FaInfoCircle />
                                &nbsp; Question was generated by AI.
                                Evaluate/proof-read the question/answers given
                                by AI. click UP/DOWN with feedback
                            </p>
                        ) : null}
                        {element === 3 ? (
                            <p className="feedback-text">
                                <FaInfoCircle />
                                &nbsp; This response created by AI. What do you
                                think?
                            </p>
                        ) : null}
                    </>
                )}

                {feedback !== null && (
                    <p
                        className={`feedback-success-text ${showSuccess || showUpdateSuccess ? "show" : ""
                            }`}>
                        <FaCheckCircle />
                        &nbsp;
                        {!showUpdateSuccess ? (
                            <>
                                {feedback === 1 &&
                                    `Thank you for your feedback. ${feedbackPercentage > 0
                                        ? `${feedbackPercentage}% of the respondents agree with you`
                                        : ""
                                    }`}
                                {feedback === 0 &&
                                    `${feedbackPercentage > 0
                                        ? `${feedbackPercentage}% of the respondents agree with you.`
                                        : `Thank you for your feedback.`
                                    } Provide additional feedback`}
                            </>
                        ) : (
                            <>{`Thank you for your feedback.`}</>
                        )}
                    </p>
                )}

                <div className={`like grow ${feedback === 1 ? "active" : ""}`}>
                    <button
                        className="light-btn"
                        onClick={() => {
                            likeUnlikeClick(1);
                        }}>
                        <FaThumbsUp />
                    </button>
                </div>

                <div
                    className={`dislike grow ${feedback === 0 ? "active" : ""
                        }`}>
                    <button
                        className="light-btn"
                        onClick={() => {
                            likeUnlikeClick(0);
                        }}>
                        <FaThumbsDown />
                    </button>
                </div>
            </div>

            <FeedbackModal
                isOpen={isModalOpen && Boolean(feedbackId)}
                onClose={() => setIsModalOpen(false)}
                onSend={onFeedbackTextSubmit}
            />
        </div>
    );
};

export default Feedback;
