import React from "react";
import MessageBox from "./Message";
import "./style.scss";
import { Video } from "../../Models/Video";
import { Course } from "../../Models/Course";
import { GptProvider } from "../../Context/GptContext";

interface ChatbotContainerProps {
    video: Video,
    collection: Course,
    isCollectionOwner: boolean,
    pauseVideo: () => void
}

const ChatbotContainer: React.FC<ChatbotContainerProps> = ({ video, collection, pauseVideo, isCollectionOwner = false }) => {
    return (
        <GptProvider collection={collection} video={video} isCollectionOwner={isCollectionOwner} pauseVideo={pauseVideo}>
            <div id="chatbot-wrapper">
                <MessageBox />
            </div>
        </GptProvider>
    );
};

export default ChatbotContainer;
